import { Component, HostListener } from '@angular/core';
import { AuthService } from './services/auth.service';
import { StorageService } from './services/storage.service';
import { ScreensizeService } from './services/screensize.service';
 
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(private platform:Platform, public authService: AuthService, private storageservice:StorageService, private screensizeService: ScreensizeService) {
    //this.storageservice.clear();
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.screensizeService.onResize(this.platform.width());
    });
  }
 
  @HostListener('window:resize', ['$event'])

  private onResize(event) {
    this.screensizeService.onResize(event.target.innerWidth);
  }  

}