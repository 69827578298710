import { Injectable, NgZone } from '@angular/core';
import * as auth from 'firebase/auth';
import { User } from './../shared/user';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { StorageService } from './../services/storage.service';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';

import { BehaviorSubject, from, Observable } from 'rxjs';
import { VariablesistemaService } from '../services/variablesistema.service';
import { auditTime, filter, map, take, tap } from 'rxjs/operators';
import { AlertController } from '@ionic/angular';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class AuthService {
  usuarioCargado: Subject<User> = new Subject<User>();
  user: Observable<User>;
  public isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);  
  public esEmailVerificado: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  public userData: any = null;
  public handleVerification: any = null;
  public userauth: any  = null;


  constructor(
    public afStore: AngularFirestore,
    public ngFireAuth: AngularFireAuth,
    public router: Router,
    public ngZone: NgZone,
    public storage: StorageService,
    public variablesistema: VariablesistemaService,
    public alertCtrl: AlertController,
  ) {


    this.ngFireAuth.authState.subscribe((user) => {
      console.log('AUTHSTATEAUTHENTICATION', user, auth.getAuth().currentUser)
      if (user) {
        console.log('USUARIOAUTHDESDEAUTH', auth)
        this.userauth = user;
        this.storage.getObject('user').then((userdata: any) => {
          console.log('dataUser',userdata);
          if( userdata !== null ){
            userdata.emailVerified = user.emailVerified;
            this.variablesistema.displayName = (userdata.displayName)?userdata.displayName:'';
            this.userData = userdata;
            this.usuarioCargado.next(this.userData);
            if( user.emailVerified ){
              this.esEmailVerificado.next(true);
            }else{
              this.esEmailVerificado.next(false);
            }
            this.isAuthenticated.next(true);

            this.storage.setObject('user', userdata);
          }else{
            // crea el usuario en el localStorage
            let newuser:any = [];
            newuser.uid = user.uid;
            newuser.email = user.email;
            newuser.emailVerified = user.emailVerified;
            newuser.empresa = '';
            newuser.displayName = (this.variablesistema.displayName !== '')?this.variablesistema.displayName:user.displayName;
            if( user.emailVerified ){
              this.esEmailVerificado.next(true);
            }else{
              this.esEmailVerificado.next(false);
            }
            console.log('datosusuarioupdate', newuser)
            this.storage.setObject('user', newuser);
            this.userData = newuser;
            this.usuarioCargado.next(this.userData);
            this.isAuthenticated.next(true);
          }
          if( user.providerData[0].providerId !== 'password' ){
            this.router.navigate(['/home'],{ replaceUrl: true });
          }
          
        });  
      } else {
        this.userauth = null; // no est� registrado o logout
        this.storage.getObject('user').then((userdata: any) => {
          console.log('dataUserSINUSUARIO',userdata, this.userauth);
          if( userdata !== null ){  // ya tiene datos del usuario en storage -> es un logout
            this.variablesistema.displayName = (userdata.displayName)?userdata.displayName:'';
            this.userData = userdata;
            this.usuarioCargado.next(this.userData);
            if( userdata.emailVerified ){
              this.esEmailVerificado.next(true);
              if( this.userauth == null ){
                // esta el email autenticado y verificado pero no existe usuario -> se ha logout
                //this.router.navigate(['/login'],{ replaceUrl: true });
              }
            }else{
              this.esEmailVerificado.next(false);
            }
            this.isAuthenticated.next(true);
          }else{
            // crea el usuario en el localStorage
            this.esEmailVerificado.next(false);
            this.userData = null;
            this.usuarioCargado.next(this.userData);
            this.isAuthenticated.next(false);
          }
        });  
      }
    });

  }

  public emitirDatosUsuario(){
    console.log('EMITIOMOSDATOSUSUARIO')
    this.usuarioCargado.next(this.userData);
  }


  chequearEmailVerificado(){
    console.log('CHEQUEAREMAILVERIFICADO', this.esEmailVerificado.value)
    if( !this.esEmailVerificado.value ){
      console.log('ELEMAILNOESTAVERIFICADO')
      this.refreshUserUntilEmailVerified().then( user => {
        console.log('REFRESHUSERUNTILWEMAILVERIFIED', user)
      } ).catch(function (message) {
        console.log('la promesa se ha rechazado', message);
      });

    }
  }

  public async refreshUserUntilEmailVerified(interval = 5000) {
    console.log('ACTIVANDO refreshUserUntilEmailVerified')
    let thisobj = this;
    await this.ngFireAuth.user
        .pipe(
            auditTime(interval),
            tap(useremail => {
              let emailVerifiedSaved = useremail.emailVerified;
              console.log('refreshUserUntilEmailVerified', useremail)
              if( useremail !== null ){
                if(!useremail.emailVerified){
                  useremail.reload().then( (usercargado) => {
                    console.log('USUARIORECARGADO', usercargado)
                    let userreload = useremail;
                    console.log('EMAILRELOAD', userreload, userreload)
                    let oemail = JSON.parse(JSON.stringify(userreload));
                    console.log('CLONADO', oemail, oemail.emailVerified)
      
                    console.log('USUARIODIFERENTENULL', oemail.emailVerified)
                    // revisamos si el emailVerified ha cambiado a true.
                    console.log('EMAILVERIFICADO', thisobj.userData.emailVerified)
                    if(  oemail.emailVerified && !thisobj.userData.emailVerified ){
                      // ha cambiado => actualizamos la info de usuario y avanzamos a la p�gina Dashboard.
                      // ACTUALIZAMOS EN FIREBASE el users
                      this.actualizarProfile({ uid: useremail.uid, emailVerified: true });
                      console.log('CAMBIAMOSINFOEMAILVERIFICADOSTORAGE')
                      this.storage.getObject('user').then((userdata: any) => {
                        console.log('dataUser',userdata);
                        if( userdata !== null ){
                          userdata.emailVerified = true;
                          this.isAuthenticated.next(true);
                          this.userData = userdata;
                          this.storage.setObject('user', userdata);
                        }else{
                          // crea el usuario en el localStorage
                          let newuser:any = [];
                          newuser.uid = useremail.uid;
                          newuser.email = useremail.email;
                          newuser.emailVerified = true;
                          newuser.displayName = (useremail.displayName !== '')?useremail.displayName:'';
                          console.log('datosusuarioupdate', newuser)
                          this.storage.setObject('user', newuser);
                          this.userData = newuser;
                          this.isAuthenticated.next(true);
                        }
                      });
                      let msg = '<div class="msgcontent"><div class="contenedorborrarpunto"> <div class="iconoborrarpunto"><img src="./assets/images/emailverified.svg" /></div></div>';
                      msg += '<div class="contenedormsgtexto">'
                      msg += '<div class="msgtexto">El email se ha confirmado correctamente !</div>'
                  
                      this.alertCtrl.create({
                        header: '',
                        message: msg,
                        cssClass: 'alertAviso',
                        backdropDismiss: false,
                        buttons: [{
                          text: 'Entrar',
                          cssClass: 'btnSi',
                          handler: () => {
                            this.router.navigate(['/home'],  { replaceUrl: true } );
                          }
                        }]
                      })
                      .then(alert => {
                        alert.present();
                      });
                    }
                  } )
                }
                if(useremail.emailVerified){
                  interval = null;
                }
              }else{
                let user = {
                  emailVerified:false
                }
              }
            }),
            filter(user => !!user?.emailVerified),
            take(1),
        ).toPromise();
  }


  // Login in with email/password
  SignIn(email, password) {
    return this.ngFireAuth.signInWithEmailAndPassword(email, password);
  }
  // Register user with email/password
  RegisterUser(email, password) {
    return this.ngFireAuth.createUserWithEmailAndPassword(email, password);
  }

  SendVerificationMail() {
    return this.ngFireAuth.currentUser.then((user) => {
      
      return user.sendEmailVerification().then(() => {
        //this.router.navigate(['login']);
      });
    });
  }

  // Recover password
  PasswordRecover(passwordResetEmail) {
    return this.ngFireAuth
      .sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        window.alert(
          'Password reset email has been sent, please check your inbox.'
        );
      })
      .catch((error) => {
        window.alert(error);
      });
  }

  // Returns true when user is looged in
  get isLoggedIn() {
    console.log('ISLOGGEDIN')
    let miPromesa = new Promise<boolean>((resolve, reject) => {
      console.log('dentrodepromesa')
      this.storage.getObject('user').then( userdata => {
        let user: any = userdata;
        console.log('RESULTADO', user)
        let res = ( typeof user !== 'undefined' ) ? true : false;
        return resolve(res); 
      })
    })
    console.log('EJECUTANDO LA PROMESA')
    return miPromesa;    
  }

  // Returns true when user's email is verified
  get isEmailVerified() {
    console.log('FUNCTIONISEMAILVERIFIED')
    let miPromesa = new Promise<boolean>((resolve, reject) => {
      this.storage.getObject('user').then( userdata => {
        let user: any = userdata;
        console.log('RESULTADOEMAILVERIFIED', user)
        console.log('ISEMAILVERIFIED', user)
        let res = ( typeof user !== 'undefined' && user.emailVerified) ? true : false;
        console.log('EMAIL VERIFICADO ES IGUAL A : ', user.emailVerified, res)
        return resolve(res); 
      })
    })
    console.log('EJECUTANDO LA PROMESAEMAILVERIFIED')
    return miPromesa;    
  }

  // Sign in with Gmail
  GoogleAuth() {
    return this.AuthLogin(new auth.GoogleAuthProvider());
  }
  FacebookAuth(){
    return this.AuthLogin( new auth.FacebookAuthProvider());
  }

  // Auth providers
  AuthLogin(provider) {
    return this.ngFireAuth
      .signInWithPopup(provider)
      .then((result) => {
        this.ngZone.run(() => {
          this.router.navigate(['home']);
        });
        this.SetUserData(result.user);
      })
      .catch((error) => {
        let msg = '<div class="msgcontent"><div class="contenedorborrarpunto"> <div class="iconoborrarpunto"><img src="./assets/images/errorwarning.svg" /></div></div>';
        msg += '<div class="contenedormsgtexto">'
        msg += '<div class="msgtexto">Error en acceso !</div>'
        msg += '<div class="msgtexto">' + error + '</div>'
        msg += '</div>'
        this.alertCtrl.create({
          header: '',
          message: msg,
          cssClass: 'alertAviso',
          backdropDismiss: false,
          buttons: [{
            text: 'Cerrar',
            cssClass: 'btnNo',
            handler: () => {
            }
          }]
        })
        .then(alert => {
          alert.present();
        });
      });
  }

  // Store user in localStorage
  SetUserData(user) {
    const userRef: AngularFirestoreDocument<any> = this.afStore.doc(
      `users/${user.uid}`
    );
    const userData: User = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      empresa: user.empresa,
      photoURL: (user.photoURL == null)?'':user.photoURL,
      emailVerified: user.emailVerified,
      tosaccepted: false,
      // avatar : 'https://firebasestorage.googleapis.com/v0/b/abcread-5e9f1.appspot.com/o/avatar.svg?alt=media&token=53c659b9-91c5-4450-a5eb-3050f462b0d8',
    };
    console.log('SETDATAUSER', userData)
    this.usuarioCargado.next(userData);

    return userRef.set(userData, {
      merge: true,
    });
  }

  async actualizarProfile( user:any ): Promise<any>{
    try {
      console.log('saveProfile', user)
      const userRef: AngularFirestoreDocument<any> = this.afStore.doc(`users/${user.uid}`);
      // const dataprofile:any = {
      //   uid: user.uid,
      //   email: user.email,
      //   emailVerified: user.emailVerified,
      //   displayName: (this.displayName !== '')?this.displayName:user.displayName,
      //   direccion1: direccion1,
      //   direccion2: direccion2,
      //   telefono: telefono,
      //   registro: registro
      // }
      return userRef.set(user, {merge:true})
    } 
    catch(error){
      console.log('Error -> ', error)
    }

  }


  // Sign-out
  SignOut() {
    return this.ngFireAuth.signOut().then(() => {
      //this.storage.removeItem('user');
      //this.router.navigate(['login']);
      this.userauth = null;
      //this.router.navigate( ['/login'],  { replaceUrl: true } );
    });
  }


}